<template>
  <b-card-code no-body title="Topup Payment Bank List">
      <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

            <div class="col-3">
                <label>Filter by User</label>
                <v-select
                    v-model="filter.user_id"
                    :options="users"
                    :reduce="option => option.id"
                    @search="onSearchUser"
                >
                    <template slot="no-options">
                    Type user name, code, or email..
                    </template>
                </v-select>
            </div>

            <div class="col-3">
                <label>Filter by Status</label>
                <select class="form-control form-control-sm" v-model="filter.status">
                    <option value="">-- All Status --</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                </select>
            </div>

        </div>
    </div>
    <br>

    <div class="table-responsive-sm">
        <table class="table b-table table-fixed">
            <thead>
            <tr>
                <th>User</th>
                <th>Bank Account</th>
                <th>File Attachment</th>
                <th>Creation Time</th>
                <th>Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="isLoading">
                <td colspan="6" class="text-center">
                <b-spinner
                    class="mb-1"
                    variant="primary"
                /><br>
                Loading...
                </td>
            </tr>
            <tr v-else v-for="item in result.data" v-bind:key="item.id">
                <td v-if="item.user != null">
                <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                    {{ item.user.code }}
                </router-link>
                </td><td v-else>-</td>
                <td>
                    Bank Name: <strong>{{ item.bank_name }}</strong><br>
                    Account Number: <strong>{{ item.account_number }}</strong><br>
                    Account Holder: <strong>{{ item.account_holder }}</strong><br>
                </td>
                <td>
                    <strong v-if="item.file_attachment_url">
                    <a v-bind:href="item.file_attachment_url" target="_blank">
                        Open File 
                        <feather-icon
                        icon="ExternalLinkIcon"
                        />
                    </a>
                    </strong><span v-else>-</span>
                </td>
                <td>{{ item.created_at }}</td>
                <td style="text-transform: capitalize;">{{ item.status }}</td>
                <td>
                    <div class="btn-group" role="group">
                        <button class="btn btn-success btn-sm" v-if="item.status != 'approved' && checkPermission('approve user-bankaccount')" @click="approve(item.uuid)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Approve">
                            <feather-icon
                                icon="CheckCircleIcon"
                            />
                        </button>
                        <button class="btn btn-danger btn-sm" v-if="item.status != 'rejected' && checkPermission('reject user-bankaccount')" @click="reject(item.uuid)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Reject">
                            <feather-icon
                                icon="XCircleIcon"
                            />
                        </button>
                    </div>
                </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
                <td colspan="6" class="text-center">Data is empty.</td>
            </tr>
            </tbody>
        </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title () {
    return `User Bank Account`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    vSelect
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
        currentPage: 1,
        result: {},
        isLoading: false,
        formPayload: Object,
        filter: {
            status: this.$route.query.status == null ? '' : this.$route.query.status,
            user_id: ''
        },
        users: [],
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function() {     
        this.getData();                                                                                                                                                                        
      }, 100),
      deep: true
    },
  },
  created() {
    this.getData();
    this.executeSearch('')
  },
  methods: {
    executeSearch(query){
      var vm = this
      this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + escape(query))
      .then(response => {
        vm.users = [];
        response.data.data.data.forEach(function(user){
          vm.users.push({
            'id': user.id,
            'label': user.code + ' (' + user.name + ')'
          })
        });

      })
    },
    onSearchUser(search, loading) {
      if(search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(escape(search))
      loading(false);
    }, 300),
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/user-bank-accounts', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    approve(uuid) {

      this.$swal({
        title: 'Are you sure?',
        text: 'User will be able to withdraw cash balance to this bank account.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('admin/user-bank-accounts/' + uuid + '/approve')
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Bank Account has been approved',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
    reject(uuid) {

      this.$swal({
        title: 'Are you sure?',
        text: 'User will not able to withdraw cash balance to this bank account.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('admin/user-bank-accounts/' + uuid + '/reject')
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Bank Account has been rejected',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>